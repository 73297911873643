
<template>
    <div class="sidebar-popular-product">
        <h3>Populer Products</h3>
        <div class="single-popular-product clearfix" v-for="item in items" :key="item.id">
            <img v-bind:src="'https://api.falconflooringreno.ca/resize/'+item.image" alt="image" class="float-left">
            <div class="product float-left">
                <h5><a :href="'/product/' + item.name" class="tran3s">{{ item.name }}</a></h5>

                <ul>
                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                    <li><i class="fa fa-star" aria-hidden="true"></i></li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';


export default {
    name: 'PopularProducts',
    data() {
        return {
            items: []
        };
    },
    mounted() {

        axios.get(`https://api.falconflooringreno.ca/popularproducts`)
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
};
</script>
  
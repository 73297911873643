<template>
  <div class="main-page-wrapper">
    <HeaderComp />
  <ContactUs />
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import ContactUs from './content.vue'
export default {
  components: {
    ContactUs,
    HeaderComp,
    FooterComp,
  },
  name: 'ContactUsPage',
  computed: {
    pageTitle() {
      return 'Falcon Flooring & Renovation | Contact Us'; // replace with the desired title for this page
	}
  },
mounted() {
    document.title = this.pageTitle;
  }



}
</script>

<template>
  <!-- Site wrapper -->
  <div class="wrapper">

    <headerComp />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1></h1>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">

        <!-- Default box -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Categories</h3>

            <div class="card-tools">
              <input type="text" v-model="searchQuery" class="btn btn-tool" placeholder="Search for Category..."
                style="border: 1px solid gray; border-radius: 5px;" />
              <button type="button" class="btn btn-tool" data-toggle="modal" data-target="#modal-xl-add">
                New Category <i class="fas fa-plus"></i>
              </button>
            </div>


          </div>
          <div class="card-body p-0">
            <table class="table table-striped projects">
              <thead>
                <tr>
                  <th style="width: 1%">
                    #
                  </th>
                  <th style="width: 20%">
                    Category Name
                  </th>
                  <th style="width: 30%">
                    Category type
                  </th>
                  <th style="width: 8%" class="text-center">
                    Image
                  </th>
                  <th style="width: 20%">
                  </th>
                </tr>
              </thead>
              <tbody>
                <!--<tr v-for="Category in items" :key="Category.id">-->
                <tr v-for="Category in paginatedItems" :key="Category.id">
                  <td>{{ Category.id }}</td>
                  <td><a>{{ Category.name }}</a></td>
                  <td v-if="Category.parent_id"><a>Sub Category</a></td>
                  <td v-else><a>Parent Category</a></td>
                  <td class="project-state">
                    <img :src="'https://api.falconflooringreno.ca/resize/'+Category.image" alt="Image from JSON"
                      style="width: 50px;height: 50px;object-fit: cover;" />

                  </td>
                  <td class="project-actions text-right">
                    <a class="btn btn-primary btn-sm" href="#" @click.prevent="openModal(Category)" data-toggle="modal"
                      data-target="#modal-xl">
                      <i class="fas fa-folder"></i>
                      View
                    </a>
                    <a class="btn btn-info btn-sm" href="#" @click.prevent="editModal(Category)" data-toggle="modal"
                      data-target="#modal-xl-edit">
                      <i class="fas fa-pencil-alt"></i>
                      Edit
                    </a>
                    <a class="btn btn-danger btn-sm" href="#" @click.prevent="removeModal(Category)" data-toggle="modal"
                      data-target="#modal-danger">
                      <i class="fas fa-trash"></i>
                      Delete
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
              <button @click="prevPage" :disabled="currentPage <= 1">Previous</button>
              <span>Page {{ currentPage }}</span>
              <button @click="nextPage" :disabled="currentPage >= totalPages">Next</button>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <footerComp />
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
  <div class="modal fade" id="modal-danger">
    <div class="modal-dialog">
      <div class="modal-content bg-danger">
        <div class="modal-header">
          <h4 class="modal-title">Delete Category</h4>
          <button type="button" class="close" data-dismiss="modal" id="removemodalclose" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure about delete Category?&hellip;</p>
          <p id="deletealert"></p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-outline-light" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-outline-light" @click.prevent="removeItem()">Delete</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <div class="modal fade" id="modal-xl-add">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Category Detail</h4>
          <button type="button" class="close" id="addmodalclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group">
              <label for="exampleInputEmail1">Category Name</label>
              <input type="email" class="form-control" id="newCategoryNameInput" placeholder="Enter Category Name">
            </div>
            <div class="form-group">
              <label>Category</label>
              <select id="categoryID2" class="form-control select2" style="width: 100%;">
                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}
                </option>
              </select>
            </div>

            <input type="hidden" id="Categoryidno">
            <!--  <textarea id="summernote"  v-model="selectedCategoryDetail.desc"></textarea> /.card-header -->
            <div class="form-group">
              <label>Category Details</label>
              <editor id="editorum2" v-model="content2" :init="{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                                                      alignleft aligncenter alignright alignjustify | \
                                                      bullist numlist outdent indent | removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }"></editor>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">Image Input</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                    id="exampleInputFile">
                  <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to change</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text" @click.prevent="selam()">Upload</span>
                </div>
              </div>
              <h5> {{ this.imagestatus }}</h5>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="button" @click.prevent="addCategory()" class="btn btn-primary">Add Category</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <div class="modal fade" id="modal-xl-edit">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Category Detail</h4>
          <button type="button" class="close" id="editmodalclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group">
              <label for="exampleInputEmail1">Category Name</label>
              <input type="email" class="form-control" id="CategoryName" placeholder="Enter Category Name">
            </div>
            <div class="form-group">
              <label>Category</label>
              <select id="categoryID" class="form-control select2" style="width: 100%;">
                <option selected="selected" :key="selectedid" :value="selectedid">{{ selectedname }}</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}
                </option>
              </select>
            </div>

            <input type="hidden" id="Categoryidno">
            <!--  <textarea id="summernote"  v-model="selectedCategoryDetail.desc"></textarea> /.card-header -->
            <div class="form-group">
              <label>Category Details</label>
              <editor id="editorum" v-model="content" :init="{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                                                      alignleft aligncenter alignright alignjustify | \
                                                      bullist numlist outdent indent | removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }"></editor>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">File input</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                    id="exampleInputFile">
                  <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to change</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text" @click.prevent="selam()">Upload</span>
                </div>
              </div>
              <h5> {{ this.imagestatus }}</h5>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal"
            @click="fetchData('myParameter')">Close</button>
          <button type="button" @click.prevent="updateCategory()" class="btn btn-primary">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <div class="modal fade" id="modal-xl">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Category Detail</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="selectedCategory"
            style=" display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <h3>Category Name : {{ selectedCategory.name }}</h3>
            <h5 v-if="selectedCategory.mainCategoryName">Parent Category Name: {{ selectedCategory.mainCategoryName }}</h5>
            <h5 v-else>This is parent category</h5>
            <h5>Category Description : {{ selectedCategory.desc }}</h5>
            <img :src="'https://api.falconflooringreno.ca/resize/'+ selectedCategory.image" alt="Category image" />
          </div>
          <div v-else>
            <p>No Category selected</p>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal"
            @click="fetchData('myParameter')">Close</button>
     
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import headerComp from '../component/header.vue'
import footerComp from '../component/footer.vue'
import Editor from '@tinymce/tinymce-vue';
//import modalServices from '../component/modals.vue'
import { state } from '../../store';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
export default {
  name: 'adminCategories',
  data() {
    return {
      items: [],
      searchQuery: '',
      currentPage: 1, // Current page number
      itemsPerPage: 10, // Number of items to display per page
      categories: null,
      selectedCategoryName: null,
      selectedCategory: null,
      selectedCategoryDetail: null,
      selectedCategoryId: null,
      selectedid: null,
      selectedname: null,
      content: null,
      content2: null,
      imagestatus: null,
      newimageUrl: null,
      newCategoryimageUrl: null
    };
  },
  components: {
    headerComp,
    footerComp,
    Editor
    //  modalServices
  },
  computed: {
    filteredItems() {
      if (this.searchQuery === '') {
        return this.items;
      }
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    pageTitle() {
      return 'Category List'; // replace with the desired title for this page
    },
    className() {
      return 'hold-transition sidebar-mini'; // replace with the desired title for this page
    }
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async addCategory() {
      let newCategoryName = document.getElementById("newCategoryNameInput").value
      let newCategoryDetail = this.content2;
      let mycat = this.categories.find(Category => Category.id == document.getElementById("categoryID2").value)
      let newCategoryCatId = mycat.id;
      
      let myimgurl = null;
      if (this.newimageUrl !== null) {
        myimgurl = `${this.newimageUrl}`;
      }
      const newCategoryData = {
        "name": newCategoryName,
        "desc": newCategoryDetail,
        "parent_id": newCategoryCatId,
        "image": myimgurl,
      };
      try {
        const response = await axios.post(`https://api.falconflooringreno.ca/Category`, newCategoryData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.items.push(newCategoryData)
        document.getElementById('addmodalclose').click();
        console.log('Category updated successfully:', response.data);
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        this.imagestatus = error;
        //console.error('Category update failed:', error);
      }
    },
    async updateCategory() {


      let mycat = this.categories.find(Category => Category.id == document.getElementById("categoryID").value) || null
      if(!mycat){
        mycat={"id":null,"name":null}
      }
      let myimgurl = null;
      if (this.newimageUrl !== null) {
        myimgurl = `${this.newimageUrl}`;
      }
      const updatedData = {
        "id": document.getElementById("Categoryidno").value,
        "name": document.getElementById("CategoryName").value,
        "desc": this.content,
        "parent_id": mycat.id,
        "image": myimgurl,
      };
      try {
        const response = await axios.patch(`https://api.falconflooringreno.ca/Category`, updatedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        let selectedindex = this.items.findIndex(Category => Category.id == parseInt(document.getElementById("Categoryidno").value));
        this.items[selectedindex].name = document.getElementById("CategoryName").value;
        this.items[selectedindex].desc = this.content;
        this.items[selectedindex].parent_id = mycat.id;
        this.items[selectedindex].categoryName = mycat.name;
        if (this.newimageUrl !== null) {
          this.items[selectedindex].image = myimgurl
        }

        this.selectedid = null;
        this.content = null;
        this.selectedid = null;
        this.selectedCategoryName = null;


        document.getElementById('editmodalclose').click();
        console.log('Category updated successfully:', response.data);
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        this.imagestatus = error;
        //console.error('Category update failed:', error);
      }
    },
    async uploadImage(event) {
      const formData = new FormData();
      const imageFile = event.target.files[0];
      formData.append('image', imageFile);
      try {
        const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.newimageUrl = response.data.dosyaUrl
        this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        this.newimageUrl = null
        this.imagestatus = `Image upload failed: ${error}`;
      }
    },
    async uploadImageForNew(event) {
      const formData = new FormData();
      const imageFile = event.target.files[0];
      formData.append('image', imageFile);
      try {
        const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.newCategoryimageUrl = response.data.dosyaUrl
        this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
      } catch (error) {
        this.newCategoryimageUrl = null
        this.imagestatus = `Image upload failed: ${error}`;
      }
    },
    openModal(item) {

      let kerem = this.categories.find(Category => Category.id == item.parent_id) || null
   console.log(kerem)
      if (kerem){
        item.mainCategoryName=kerem.name
      }
      
      this.selectedCategory = item;
     // console.log(item)
    },
    editModal(item) {
      console.log(item);
      document.getElementById("CategoryName").value = item.name;
      document.getElementById("Categoryidno").value = item.id;

      this.content = item.desc;
      let parent_id= item.parent_id || null
      if(parent_id){
      this.selectedid = item.parent_id;
      this.selectedCategoryName = this.categories.find(Category => Category.id == item.parent_id).name
      this.selectedname = this.categories.find(Category => Category.id == item.parent_id).name;
      document.getElementById("categoryID").value = this.selectedCategoryName;
      }else{
        this.selectedid=null;
        this.selectedCategoryName=null;
        this.selectedname=null;
      }

      this.selectedCategoryDetail = {
        CategoryID: item.id,
        CategoryName: item.name,
        CategoryDetail: item.desc,
        categoryID: item.parent_id,
        image: item.image
      }
    },
    removeModal(item) {
      document.getElementById("deletealert").innerText = item.name;
      this.selectedCategoryId = item.id;
      this.content = item.desc;
      this.selecte
      this.selectedid = item.parent_id;
      this.selectedCategoryName = this.categories.find(Category => Category.id == item.parent_id).name
      this.selectedname = this.categories.find(Category => Category.id == item.parent_id).name;
    },
    removeItem() {
      let selectedindex = this.items.findIndex(Category => Category.id == this.selectedCategoryId);

      axios.delete(`https://api.falconflooringreno.ca/Category/${this.selectedCategoryId}`)
        .then(response => {
          console.log('Item deleted successfully:', response.data);
          this.items.splice(selectedindex, 1);
          document.getElementById('removemodalclose').click();
        })
        .catch(error => {
          if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
          console.error('Error deleting item:', error);
        });
    },


  },

  mounted() {



    document.body.className = this.className;
    document.title = this.pageTitle;
    //const route = useRoute();

    axios.get(`https://api.falconflooringreno.ca/Category`)
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        console.log(error);
      });
    axios.get(`https://api.falconflooringreno.ca/Category`)
      .then(response => {
        this.categories = response.data;
      })
      .catch(error => {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        console.log(error);
      });
  }



}
</script>

<template>
    <div class="main-page-wrapper" v-if="items">
        <HeaderComp />
        <div class="inner-banner">
            <div class="opacity">
                <div class="container">
                    <h2>Products</h2>
                    <p>Flooring &amp; Tiles has been a presence on the international market since 1962 and is part of
                        <br>the Gruppo Concorde, the largest ceramic group in Europe.
                    </p>


                </div>
            </div>
        </div>
        <Requestquote />
        <div class="shop-page">
            <div class="container">
                <div class="main-wrapper">
                    <div class="col float-right shop-details shop-large-side" v-if="items">
                        <div class="single-product-details clearfix">
                            <div class="image float-left">
                                <img :src="'https://api.falconflooringreno.ca/resize/'+items.image" alt="">

                            </div> <!-- /.image -->
                            <div class="info float-left">
                                <h4>{{items.name}}</h4>

                                <p v-html="items.detail"></p>
                            </div> <!-- /.info -->
                        </div> <!-- /.single-product-details -->

                        <div class="review-tab">
                            <ul class="nav nav-tabs">
                                <li class="active"><a data-toggle="tab" href="#home">Description</a></li>
                            </ul>
                            <div class="tab-content">
                                <div id="home" class="tab-pane fade in active" v-html="items.detail">
                                </div>

                            </div>
                        </div>

                        <reletedProducts />

                    </div> <!-- /.shop-large-side -->
                    <!-- =========================== SHOP SIDEBAR ============================= -->
                    <div class="col float-left shop-sidebar">

                        <CategoryTree />



                        <PopularProducts />


                    </div> <!-- /.shop-sidebar -->
                </div> <!-- /.main-wrapper -->
            </div> <!-- /.container -->
        </div>




        <FooterComp />
    </div>
</template>

<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import Requestquote from '../extas/requestquote.vue';
import CategoryTree from './CategoryTree.vue'
import PopularProducts from './PopularProducts.vue'
import reletedProducts from './reletedProducts.vue'
import axios from 'axios';
import { useRoute } from "vue-router";
export default {
    
    components: {
        HeaderComp,
        FooterComp,
        Requestquote,
        reletedProducts,
        PopularProducts,
        CategoryTree
    },
    name: 'productDetail',
    data() {
    return {
      items: [],
      productName:null,
    };
  },
    computed: {
        pageTitle() {
            return 'Falcon Flooring & Renovation | '; // replace with the desired title for this page
        }
    },
    mounted() {
        const route = useRoute();

        this.productName = "/" + route.params.productName ?? "";
    
        axios.get(`https://api.falconflooringreno.ca/product${this.productName}`)
            .then(response => {
                this.items = response.data[0];
              document.title = this.pageTitle + this.items.name;
            })
            .catch(error => {
                console.log(error);
            });
      

    }



}
</script>

<template>
	<div class="main-page-wrapper">
		<HeaderComp />
		<div class="inner-banner">
			<div class="opacity">
				<div class="container">
					<h2>Services</h2>
					<p>Flooring &amp; Tiles has been a presence on the international market since 1962 and is part of
						<br>the Gruppo Concorde, the largest ceramic group in Europe.
					</p>
					<div class="clearfix">

					</div>
				</div> <!-- /.container -->
			</div> <!-- /.opacity -->
		</div>
		<requestQuote />
		<div class="our-service m-top0">
			<div class="container">
				<div class="row">
					<div class="clearfix">
						<serviceList />
					</div> <!-- /.service-sldier -->
				</div> <!-- /.row -->
			</div> <!-- /.container -->
		</div>
		<FooterComp />
	</div>
</template>
  
<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import requestQuote from '../extas/requestquote.vue';
import serviceList from './serviceList.vue'
//requestQuote
export default {
	components: {
		HeaderComp,
		FooterComp,
		requestQuote,
		serviceList
	},
	name: 'servicesPage',
	computed: {
		pageTitle() {
			return 'Falcon Flooring & Renovation | Services'; // replace with the desired title for this page
		}
	},
	mounted() {
		document.title = this.pageTitle;
	}



}
</script>
  
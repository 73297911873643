<template>
    <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
     
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      
     
      
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      
    </ul>
  </nav>
  <!-- /.navbar -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="#" class="brand-link">
     
      <span class="brand-text font-weight-light">Falcon Flooring</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
    <sidebarSearchForm />
      <sidebarMenu />
    </div>
    <!-- /.sidebar -->
  </aside>
  
</template>
<script>
import sidebarSearchForm from '../component/sidebarSearchForm.vue'
import sidebarMenu from '../component/sidebarMenu.vue';
export default {
  name: 'headerComp',
  components:{
        sidebarMenu,
        sidebarSearchForm
    },
}
</script>
<template>


	<div class="inner-banner">
		<div class="opacity">
			<div class="container">
				<h2>Contact</h2>
				<p>Flooring &amp; Tiles has been a presence on the international market since 1962 and is part of <br>the
					Gruppo Concorde, the largest ceramic group in Europe.</p>
				<div class="clearfix">
		
				</div>
			</div> <!-- /.container -->
		</div> <!-- /.opacity -->
	</div>
	<requestQuote />
	<div class="contact-form">
		<div class="container">
			<form action="https://api.falconflooringreno.ca/contactform" class="form-validation" autocomplete="off">
				<div class="row">
					<div class="col-sm-6"><input type="text" placeholder="Name" name="name"></div>
					<div class="col-sm-6"><input type="email" placeholder="Email" name="email"></div>
				</div>
				<textarea placeholder="Message" name="message"></textarea>
				<button class="theme-button p-bg-color hvr-float-shadow">Submit Message</button>
			</form>
			<div class="alert-wrapper" id="alert-success">
				<div id="success">
					<button class="closeAlert"><i class="fa fa-times" aria-hidden="true"></i></button>
					<div class="wrapper">
						<p>Your message was sent successfully.</p>
					</div>
				</div>
			</div>
			<div class="alert-wrapper" id="alert-error">
				<div id="error">
					<button class="closeAlert"><i class="fa fa-times" aria-hidden="true"></i></button>
					<div class="wrapper">
						<p>Sorry!Something Went Wrong.</p>
					</div>
				</div>
			</div>
		
		</div>
	</div>
	<div id="google-map-area">
		<GoogleMap2 />
	</div>


</template>
<script>
import GoogleMap2 from '../extas/googlemap.vue'
import  requestQuote from '../extas/requestquote.vue'
export default {
	name: 'contactUs',
	components:{
		GoogleMap2,
		requestQuote
	}


}
</script>
<template>
   <div class="request-quote-section">
				<div class="quote-banner">
					<div class="container">
						<h6 class="float-left">Need Flooring advice? Ask our experts for free. Glad to help you!</h6>
						<a href="#" class="theme-button p-bg-color hvr-float-shadow float-right">Request a Quote</a>
					</div>
				</div>
			</div> 
  </template>
  <script>
  export default {

    name: 'requestQuote',
  
  
  
  }
  </script>
  
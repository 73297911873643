<template>
    <!-- Site wrapper -->
    <div class="wrapper">

        <headerComp />

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1></h1>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">

                <!-- Default box -->
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Settings</h3>

                        <div class="card-tools">
                            <input type="text" v-model="searchQuery" class="btn btn-tool"
                                placeholder="Search for Setting..." style="border: 1px solid gray; border-radius: 5px;" />
                        
                        </div>


                    </div>
                    <div class="card-body p-0">
                        <table class="table table-striped projects">
                            <thead>
                                <tr>
                                    <th style="width: 1%">
                                        #
                                    </th>
                                    <th style="width: 20%">
                                        Type
                                    </th>
                                    <th style="width: 30%">
                                        Detail
                                    </th>
                                    <th style="width: 20%">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <!--<tr v-for="Setting in items" :key="Setting.id">-->
                                <tr v-for="Setting in paginatedItems" :key="Setting.id">
                                    <td>{{ Setting.id }}</td>
                                    <td><a>{{ Setting.type }}</a></td>
                                    <td><a> <img v-if="Setting.type === 'headerlogo' || Setting.type === 'aboutlogo'"
                                                :src="`https://falconflooringreno.ca${Setting.code}`" alt="Image from JSON"
                                                style="width: 50px; height: 50px; object-fit: cover;" />
                                            <div v-else>
                                                {{ Setting.code }}
                                            </div>
                                        </a></td>
                                    <td class="project-actions text-right">
                                      
                                        <a class="btn btn-info btn-sm" href="#" @click.prevent="editModal(Setting)"
                                            data-toggle="modal" data-target="#modal-xl-edit">
                                            <i class="fas fa-pencil-alt"></i>
                                            Edit
                                        </a>
                                      
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="pagination">
                            <button @click="prevPage" :disabled="currentPage <= 1">Previous</button>
                            <span>Page {{ currentPage }}</span>
                            <button @click="nextPage" :disabled="currentPage >= totalPages">Next</button>
                        </div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->

            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->

        <footerComp />
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
    <div class="modal fade" id="modal-danger">
        <div class="modal-dialog">
            <div class="modal-content bg-danger">
                <div class="modal-header">
                    <h4 class="modal-title">Delete Setting</h4>
                    <button type="button" class="close" data-dismiss="modal" id="removemodalclose" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Are you sure about delete Setting?&hellip;</p>
                    <p id="deletealert"></p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-outline-light" data-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-outline-light" @click.prevent="removeItem()">Delete</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>

    <div class="modal fade" id="modal-xl-add">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Setting Detail</h4>
                    <button type="button" class="close" id="addmodalclose" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Setting Name</label>
                            <input type="email" class="form-control" id="newSettingNameInput"
                                placeholder="Enter Setting Name">
                        </div>
                        <div class="form-group">
                            <label>Category</label>
                            <select id="categoryID2" class="form-control select2" style="width: 100%;">
                                <option v-for="category in categories" :key="category.id" :value="category.id">{{
                                    category.name }}
                                </option>
                            </select>
                        </div>

                        <input type="hidden" id="Settingid">
                        <!--  <textarea id="summernote"  v-model="selectedSettingDetail.detail"></textarea> /.card-header -->
                        <div class="form-group">
                            <label>Setting Details</label>
                            <editor id="editorum2" v-model="content2" :init="{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                                                                        alignleft aligncenter alignright alignjustify | \
                                                                        bullist numlist outdent indent | removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }"></editor>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputFile">Image Input</label>
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                                        id="exampleInputFile">
                                    <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to
                                        change</label>
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text" @click.prevent="selam()">Upload</span>
                                </div>
                            </div>
                            <h5> {{ this.imagestatus }}</h5>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="button" @click.prevent="addSetting()" class="btn btn-primary">Add Setting</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="modal-xl-edit">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Setting Detail</h4>
                    <button type="button" class="close" id="editmodalclose" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Setting Name</label>
                            <input type="email" class="form-control" id="SettingName" placeholder="Enter Setting Name">
                        </div>
                

                        <input type="hidden" id="Settingid">
                        <!--  <textarea id="summernote"  v-model="selectedSettingDetail.detail"></textarea> /.card-header -->
                        <div class="form-group">
                            <label>Setting Details</label>
                            <img v-if="selectedtype === 'headerlogo' || selectedtype === 'aboutlogo'"
                                                :src="`https://falconflooringreno.ca${selectedSettingDetail.code}`" alt="Image from JSON"
                                                style="width: 50px; height: 50px; object-fit: cover;" />
                                            <div v-else>
                            <editor id="editorum" v-model="content" :init="{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                                                                        alignleft aligncenter alignright alignjustify | \
                                                                        bullist numlist outdent indent | removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }"></editor>
                            </div>
                        </div>
                        <div v-if="selectedtype === 'headerlogo' || selectedtype === 'aboutlogo'" class="form-group">
                            <label for="exampleInputFile">File input</label>
                            <div class="input-group">
                                <div class="custom-file">
                                    <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                                        id="exampleInputFile">
                                    <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to
                                        change</label>
                                </div>
                                <div class="input-group-append">
                                    <span class="input-group-text" @click.prevent="selam()">Upload</span>
                                </div>
                            </div>
                            <h5> {{ this.imagestatus }}</h5>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal"
                        @click="fetchData('myParameter')">Close</button>
                    <button type="button" @click.prevent="updateSetting()" class="btn btn-primary">Save changes</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="modal-xl">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Setting Detail</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedSetting"
                        style=" display: flex;flex-direction: column;align-items: center;justify-content: center;">
                        <h3>Setting Type : {{ selectedSetting.type }}</h3>
                        <h5>Detail: <img v-if="selectedSetting.type === 'headerlogo' || selectedSetting.type === 'aboutlogo'"
                                                :src="`https://falconflooringreno.ca${selectedSetting.code}`" alt="Image from JSON"
                                                style="width: 50px; height: 50px; object-fit: cover;" />
                                            <div v-else>
                                                <div v-html="selectedSetting.code"></div>
                                            </div></h5>
                        <hr>
                    </div>
                    <div v-else>
                        <p>No Setting selected</p>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal"
                        @click="fetchData('myParameter')">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>
  
<script>
import headerComp from '../component/header.vue'
import footerComp from '../component/footer.vue'
import Editor from '@tinymce/tinymce-vue';
//import modalServices from '../component/modals.vue'
import { state } from '../../store';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
export default {
    name: 'adminSettings',
    data() {
        return {
            items: [],
            searchQuery: '',
            currentPage: 1, // Current page number
            itemsPerPage: 10, // Number of items to display per page
            categories: null,
            selectedCategoryName: null,
            selectedSetting: null,
            selectedSettingDetail: null,
            selectedSettingId: null,
            selectedid: null,
            selectedname: null,
            content: null,
            content2: null,
            imagestatus: null,
            newimageUrl: null,
            newSettingimageUrl: null,
            selectedtype:null,
            selectedcode:null,
        };
    },
    components: {
        headerComp,
        footerComp,
        Editor
        //  modalServices
    },
    computed: {
        filteredItems() {
            if (this.searchQuery === '') {
                return this.items;
            }
            return this.items.filter((item) =>
                item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
        paginatedItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredItems.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredItems.length / this.itemsPerPage);
        },
        pageTitle() {
            return 'Settings'; // replace with the desired title for this page
        },
        className() {
            return 'hold-transition sidebar-mini'; // replace with the desired title for this page
        }
    },
    methods: {
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        async addSetting() {
            let newSettingName = document.getElementById("newSettingNameInput").value
            let newSettingDetail = this.content2;
            let mycat = this.categories.find(Setting => Setting.id == document.getElementById("categoryID2").value)
            let newSettingCatId = mycat.id;
            let newSettingCatName = mycat.name;

            let myimgurl = null;
            if (this.newimageUrl !== null) {
                myimgurl = `${this.newimageUrl}`;
            }
            const newSettingData = {
                "name": newSettingName,
                "detail": newSettingDetail,
                "category_id": newSettingCatId,
                "image": myimgurl,
                "categoryName": newSettingCatName
            };
            try {
                const response = await axios.post(`https://api.falconflooringreno.ca/Setting`, newSettingData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                this.items.push(newSettingData)
                document.getElementById('addmodalclose').click();
                console.log('Setting updated successfully:', response.data);
            } catch (error) {
                if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                this.imagestatus = error;
                //console.error('Setting update failed:', error);
            }
        },
        async updateSetting() {


           
            let myimgurl = null;
            if (this.newimageUrl !== null) {
                myimgurl = `${this.newimageUrl}`;
            }else{
                myimgurl=this.content
            }
            
            const updatedData = {
                "id": document.getElementById("Settingid").value,
                "code": myimgurl
            };
            try {
                const response = await axios.patch(`https://api.falconflooringreno.ca/Setting`, updatedData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                let selectedindex = this.items.findIndex(Setting => Setting.id == parseInt(document.getElementById("Settingid").value));
                this.items[selectedindex].type = document.getElementById("SettingName").value;
                this.items[selectedindex].code = this.content;
                if (this.newimageUrl !== null) {
                    this.items[selectedindex].image = myimgurl
                }

                this.selectedid = null;
                this.content = null;
                this.selectedid = null;
                this.selectedCategoryName = null;


                document.getElementById('editmodalclose').click();
                console.log('Setting updated successfully:', response.data);
            } catch (error) {
                if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                this.imagestatus = error;
                //console.error('Setting update failed:', error);
            }
        },
        async uploadImage(event) {
            const formData = new FormData();
            const imageFile = event.target.files[0];
            formData.append('image', imageFile);
            try {
                const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.newimageUrl = response.data.dosyaUrl
                this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
            } catch (error) {
                if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                this.newimageUrl = null
                this.imagestatus = `Image upload failed: ${error}`;
            }
        },
        async uploadImageForNew(event) {
            const formData = new FormData();
            const imageFile = event.target.files[0];
            formData.append('image', imageFile);
            try {
                const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.newSettingimageUrl = response.data.dosyaUrl
                this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
            } catch (error) {
                if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                this.newSettingimageUrl = null
                this.imagestatus = `Image upload failed: ${error}`;
            }
        },
        openModal(item) {
          
            this.selectedSetting = item;
              console.log(this.selectedSetting)
        },
        editModal(item) {
            console.log(item);
            document.getElementById("SettingName").value = item.type;
            document.getElementById("Settingid").value = item.id;

            this.content = item.code;
            this.selectedSettingDetail = {
                SettingID: item.id,
                type: item.type,
                code: item.code,
            }
            this.selectedtype=item.type;
            this.selectedcode=item.code
            console.log(this.selectedSettingDetail.type)
        },
        removeModal(item) {
            document.getElementById("deletealert").innerText = item.name;
            this.selectedSettingId = item.id;
            this.content = item.code;
            this.selectedid = item.category_id;
            this.selectedCategoryName = this.categories.find(Setting => Setting.id == item.category_id).name
            this.selectedname = this.categories.find(Setting => Setting.id == item.category_id).name;
        },
        removeItem() {
            let selectedindex = this.items.findIndex(Setting => Setting.id == this.selectedSettingId);

            axios.delete(`https://api.falconflooringreno.ca/Setting/${this.selectedSettingId}`)
                .then(response => {
                    console.log('Item deleted successfully:', response.data);
                    this.items.splice(selectedindex, 1);
                    document.getElementById('removemodalclose').click();
                })
                .catch(error => {
                    if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                    console.error('Error deleting item:', error);
                });
        },


    },

    mounted() {



        document.body.className = this.className;
        document.title = this.pageTitle;
        //const route = useRoute();

        axios.get(`https://api.falconflooringreno.ca/Setting`)
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                console.log(error);
            });
        axios.get(`https://api.falconflooringreno.ca/categorylist`)
            .then(response => {
                this.categories = response.data;
            })
            .catch(error => {
                if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
                console.log(error);
            });
    }



}
</script>
  
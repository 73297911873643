import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from './src/components/homepage/HelloWorld.vue';
import aboutPage from './src/components/about/aboutpage.vue';
import ContactUsPage from './src/components/contactus/page.vue';
import servicesPage from './src/components/services/servicesPage.vue';
import categoryPage from './src/components/products/products.vue';
import productDetail from './src/components/products/productdetail.vue';
import pagePage from './src/components/pages/page.vue';
//admin
import adminLogin from './src/admin/pages/login.vue';
import adminProducts from './src/admin/pages/products.vue';
import adminCategories from './src/admin/pages/categories.vue';
import adminServices from './src/admin/pages/services.vue'
import adminPartners from './src/admin/pages/partners.vue'
import adminMyPage from './src/admin/pages/pages.vue';
import adminSlider from './src/admin/pages/slider.vue';
import adminSettings from './src/admin/pages/settings.vue';
//adminSlider
const routes = [
  { path: '/', redirect: '/home'},
  { path: '/home', name:'home', component: HelloWorld },
  { path: '/category/:categoryName?', name:'categories', component: categoryPage },
  { path: '/product/:productName?', name:'productDetail', component: productDetail },
  { path: '/page/:pageName?', name:'page', component: pagePage },
  { path: '/contactus', name:'ContactUsPage', component: ContactUsPage },
  { path: '/services', name:'services', component: servicesPage },
  { path: '/about', name:'about', component: aboutPage },

  { path: '/admin/', redirect: '/admin/login',  meta: { requiresAuth: true }},
  { path: '/admin/home', name:'adminhome', component: HelloWorld ,  meta: { requiresAuth: true }},
  { path: '/admin/login', name:'adminLogin', component: adminLogin },
  { path: '/admin/cats', name:'adminhome', component: HelloWorld ,  meta: { requiresAuth: true }},
  { path: '/admin/products', name:'adminProducts', component: adminProducts,  meta: { requiresAuth: true } },
  { path: '/admin/categories', name:'adminCategories', component: adminCategories,  meta: { requiresAuth: true } },
  { path: '/admin/services', name:'adminServices', component: adminServices,  meta: { requiresAuth: true } },
  { path: '/admin/mypages', name:'adminMyPage', component: adminMyPage,  meta: { requiresAuth: true } },
  { path: '/admin/partners', name:'adminPartners', component: adminPartners,  meta: { requiresAuth: true } },
  { path: '/admin/settings', name:'adminSettings', component: adminSettings,  meta: { requiresAuth: true } },
  { path: '/admin/slider', name:'adminSlider', component: adminSlider ,  meta: { requiresAuth: true }},

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
import axios from 'axios';
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.meta.requiresAuth) {
    if (!token) {
      next({ name: 'adminLogin' });
    } else {
      axios.post('https://api.falconflooringreno.ca/verify-token', {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(() => {
        next();
      })
      .catch(() => {
        localStorage.removeItem('token');
        next({ name: 'adminLogin' });
      });
    }
  } else {
    next();
  }
});

export default router;

<template>
    <div class="main-page-wrapper">
        <HeaderComp />
        <div class="inner-banner">
            <div class="opacity">
                <div class="container">
                    <h2>Products</h2>
                    <p>Flooring &amp; Tiles has been a presence on the international market since 1962 and is part of
                        <br>the Gruppo Concorde, the largest ceramic group in Europe.</p>


                </div>
            </div>
        </div>
        <Requestquote />
        <div class="shop-page">
            <div class="container">
                <div class="main-wrapper">
                    <div class="col float-right all-product-wrapper shop-large-side">
                       
                            <productList />
                       
                    </div> <!-- /.shop-large-side -->
                    <!-- =========================== SHOP SIDEBAR ============================= -->
                    <div class="col float-left shop-sidebar">

                            <CategoryTree />
                 


                        <PopularProducts />


                    </div> <!-- /.shop-sidebar -->
                </div> <!-- /.main-wrapper -->
            </div> <!-- /.container -->
        </div>




        <FooterComp />
    </div>
</template>

<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import Requestquote from '../extas/requestquote.vue';
import productList from './productlist.vue'
import CategoryTree from './CategoryTree.vue'
import PopularProducts from './PopularProducts.vue'
export default {
    components: {
        HeaderComp,
        FooterComp,
        Requestquote,
        productList,
        PopularProducts,
        CategoryTree
    },
    name: 'aboutPage',
    computed: {
        pageTitle() {
            return 'Falcon Flooring & Renovation | About'; // replace with the desired title for this page
        }
    },
    mounted() {
        document.title = this.pageTitle;
    }



}
</script>

<template>
  <!-- Site wrapper -->
  <div class="wrapper">

    <headerComp />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1></h1>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <!-- Main content -->
      <section class="content">

        <!-- Default box -->
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Slider</h3>
          
            <div class="card-tools">
              <input type="text" v-model="searchQuery" class="btn btn-tool" placeholder="Search for Slider..." 
style="border: 1px solid gray; border-radius: 5px;"/>
              <button type="button" class="btn btn-tool" data-toggle="modal" data-target="#modal-xl-add">
                New Slider <i class="fas fa-plus"></i>
              </button>
            </div>


          </div>
          <div class="card-body p-0">
            <table class="table table-striped projects">
              <thead>
                <tr>
                  <th style="width: 1%">
                    #
                  </th>
                  <th style="width: 20%">
                    Title
                  </th>
                  <th style="width: 30%">
                    Detail
                  </th>
                  <th style="width: 8%" class="text-center">
                    Image
                  </th>
                  <th style="width: 20%">
                  </th>
                </tr>
              </thead>
              <tbody>
                <!--<tr v-for="Slider in items" :key="Slider.id">-->
                  <tr v-for="Slider in paginatedItems" :key="Slider.id">
                  <td>{{ Slider.id }}</td>
                  <td><a>{{ Slider.name }}</a></td>
                  <td><a>{{ Slider.desc }}</a></td>
                  <td class="project-state">
                    <img :src="'https://api.falconflooringreno.ca/resize/'+ Slider.image" alt="Image from JSON"
                      style="width: 50px;height: 50px;object-fit: cover;" />

                  </td>
                  <td class="project-actions text-right">
                    <a class="btn btn-primary btn-sm" href="#" @click.prevent="openModal(Slider)" data-toggle="modal"
                      data-target="#modal-xl">
                      <i class="fas fa-folder"></i>
                      View
                    </a>
                    <a class="btn btn-info btn-sm" href="#" @click.prevent="editModal(Slider)" data-toggle="modal"
                      data-target="#modal-xl-edit">
                      <i class="fas fa-pencil-alt"></i>
                      Edit
                    </a>
                    <a class="btn btn-danger btn-sm" href="#" @click.prevent="removeModal(Slider)" data-toggle="modal"
                      data-target="#modal-danger">
                      <i class="fas fa-trash"></i>
                      Delete
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination">
  <button @click="prevPage" :disabled="currentPage <= 1">Previous</button>
  <span>Page {{ currentPage }}</span>
  <button @click="nextPage" :disabled="currentPage >= totalPages">Next</button>
</div>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <footerComp />
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
  <div class="modal fade" id="modal-danger">
    <div class="modal-dialog">
      <div class="modal-content bg-danger">
        <div class="modal-header">
          <h4 class="modal-title">Delete Slider</h4>
          <button type="button" class="close" data-dismiss="modal" id="removemodalclose" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Are you sure about delete Slider?&hellip;</p>
          <p id="deletealert"></p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-outline-light" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-outline-light" @click.prevent="removeItem()">Delete</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>

  <div class="modal fade" id="modal-xl-add">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Slider Detail</h4>
          <button type="button" class="close" id="addmodalclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group">
              <label for="exampleInputEmail1">Slider Name</label>
              <input type="email" class="form-control" id="newSliderNameInput" placeholder="Enter Slider Name">
            </div>
            <div class="form-group">
              <label>Category</label>
              <select id="categoryID2" class="form-control select2" style="width: 100%;">
                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}
                </option>
              </select>
            </div>

            <input type="hidden" id="Sliderid">
            <!--  <textarea id="summernote"  v-model="selectedSliderDetail.detail"></textarea> /.card-header -->
            <div class="form-group">
              <label>Slider Details</label>
              <editor id="editorum2" v-model="content2" :init="{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }"></editor>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">Image Input</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                    id="exampleInputFile">
                  <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to change</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text" @click.prevent="selam()">Upload</span>
                </div>
              </div>
              <h5> {{ this.imagestatus }}</h5>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
          <button type="button" @click.prevent="addSlider()" class="btn btn-primary">Add Slider</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <div class="modal fade" id="modal-xl-edit">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Slider Detail</h4>
          <button type="button" class="close" id="editmodalclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group">
              <label for="exampleInputEmail1">Slider Name</label>
              <input type="email" class="form-control" id="SliderName" placeholder="Enter Slider Name">
            </div>
            <div class="form-group">
              <label>Category</label>
              <select id="categoryID" class="form-control select2" style="width: 100%;">
                <option selected="selected" :key="selectedid" :value="selectedid">{{ selectedname }}</option>
                <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}
                </option>
              </select>
            </div>

            <input type="hidden" id="Sliderid">
            <!--  <textarea id="summernote"  v-model="selectedSliderDetail.detail"></textarea> /.card-header -->
            <div class="form-group">
              <label>Slider Details</label>
              <editor id="editorum" v-model="content" :init="{
                height: 300,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }"></editor>
            </div>
            <div class="form-group">
              <label for="exampleInputFile">File input</label>
              <div class="input-group">
                <div class="custom-file">
                  <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                    id="exampleInputFile">
                  <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to change</label>
                </div>
                <div class="input-group-append">
                  <span class="input-group-text" @click.prevent="selam()">Upload</span>
                </div>
              </div>
              <h5> {{ this.imagestatus }}</h5>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal"
            @click="fetchData('myParameter')">Close</button>
          <button type="button" @click.prevent="updateSlider()" class="btn btn-primary">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <div class="modal fade" id="modal-xl">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Slider Detail</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="selectedSlider"
            style=" display: flex;flex-direction: column;align-items: center;justify-content: center;">
            <h3>Slider Name : {{ selectedSlider.name }}</h3>
            <h5>Category ID: {{ selectedSlider.categoryName }}</h5>
            <h5>Created Time: {{ selectedSlider.createdTime }}</h5>
            <hr>

            <h5>Slider Description : {{ selectedSlider.detail }}</h5>
            <img :src="'https://api.falconflooringreno.ca/resize/'+ selectedSlider.image" alt="Slider image" />
          </div>
          <div v-else>
            <p>No Slider selected</p>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal"
            @click="fetchData('myParameter')">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import headerComp from '../component/header.vue'
import footerComp from '../component/footer.vue'
import Editor from '@tinymce/tinymce-vue';
//import modalServices from '../component/modals.vue'
import { state } from '../../store';
import axios from 'axios';

axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
export default {
  name: 'adminSlider',
  data() {
    return {
      items: [],
      searchQuery: '',
      currentPage: 1, // Current page number
       itemsPerPage: 10, // Number of items to display per page
      categories: null,
      selectedCategoryName: null,
      selectedSlider: null,
      selectedSliderDetail: null,
      selectedSliderId: null,
      selectedid: null,
      selectedname: null,
      content: null,
      content2: null,
      imagestatus: null,
      newimageUrl: null,
      newSliderimageUrl: null
    };
  },
  components: {
    headerComp,
    footerComp,
    Editor
    //  modalServices
  },
  computed: {
    filteredItems() {
    if (this.searchQuery === '') {
      return this.items;
    }
    return this.items.filter((item) =>
      item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  },
  paginatedItems() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredItems.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.filteredItems.length / this.itemsPerPage);
  },
    pageTitle() {
      return 'Slider List'; // replace with the desired title for this page
    },
    className() {
      return 'hold-transition sidebar-mini'; // replace with the desired title for this page
    }
  },
  methods: {
    prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  },
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  },
    async addSlider() {
      let newSliderName = document.getElementById("newSliderNameInput").value
      let newSliderDetail = this.content2;
      let mycat = this.categories.find(Slider => Slider.id == document.getElementById("categoryID2").value)
      let newSliderCatId=mycat.id;
      let newSliderCatName=mycat.name;

      let myimgurl = null;
      if (this.newimageUrl !== null) {
        myimgurl = `${this.newimageUrl}`;
      }
      const newSliderData = {
        "name": newSliderName,
        "detail": newSliderDetail,
        "category_id": newSliderCatId,
        "image": myimgurl,
        "categoryName": newSliderCatName
      };
      try {
        const response = await axios.post(`https://api.falconflooringreno.ca/Slider`, newSliderData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.items.push(newSliderData)
        document.getElementById('addmodalclose').click();
        console.log('Slider updated successfully:', response.data);
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        this.imagestatus = error;
        //console.error('Slider update failed:', error);
      }
    },
    async updateSlider() {


      let mycat = this.categories.find(Slider => Slider.id == document.getElementById("categoryID").value)
      let myimgurl = null;
      if (this.newimageUrl !== null) {
        myimgurl = `${this.newimageUrl}`;
      }
      const updatedData = {
        "id": document.getElementById("Sliderid").value,
        "name": document.getElementById("SliderName").value,
        "detail": this.content,
        "category_id": mycat.id,
        "image": myimgurl,
        "categoryName": mycat.name
      };
      try {
        const response = await axios.patch(`https://api.falconflooringreno.ca/Slider`, updatedData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        let selectedindex = this.items.findIndex(Slider => Slider.id == parseInt(document.getElementById("Sliderid").value));
        this.items[selectedindex].name = document.getElementById("SliderName").value;
        this.items[selectedindex].detail = this.content;
        this.items[selectedindex].category_id = mycat.id;
        this.items[selectedindex].categoryName = mycat.name;
        if (this.newimageUrl !== null) {
          this.items[selectedindex].image = myimgurl
        }

        this.selectedid = null;
        this.content = null;
        this.selectedid = null;
        this.selectedCategoryName = null;


        document.getElementById('editmodalclose').click();
        console.log('Slider updated successfully:', response.data);
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        this.imagestatus = error;
        //console.error('Slider update failed:', error);
      }
    },
    async uploadImage(event) {
      const formData = new FormData();
      const imageFile = event.target.files[0];
      formData.append('image', imageFile);
      try {
        const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.newimageUrl = response.data.dosyaUrl
        this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
      } catch (error) {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        this.newimageUrl = null
        this.imagestatus = `Image upload failed: ${error}`;
      }
    },
    async uploadImageForNew(event) {
      const formData = new FormData();
      const imageFile = event.target.files[0];
      formData.append('image', imageFile);
      try {
        const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.newSliderimageUrl = response.data.dosyaUrl
        this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
      } catch (error) {
        this.newSliderimageUrl = null
        this.imagestatus = `Image upload failed: ${error}`;
      }
    },
    openModal(item) {
      this.selectedSlider = item;
    },
    editModal(item) {
      console.log(item);
      document.getElementById("SliderName").value = item.name;
      document.getElementById("Sliderid").value = item.id;

      this.content = item.detail;
      this.selectedid = item.category_id;
      this.selectedCategoryName = this.categories.find(Slider => Slider.id == item.category_id).name
      this.selectedname = this.categories.find(Slider => Slider.id == item.category_id).name;
      document.getElementById("categoryID").value = this.selectedCategoryName;
      this.selectedSliderDetail = {
        SliderID: item.id,
        SliderName: item.name,
        SliderDetail: item.detail,
        categoryID: item.category_id,
        image: item.image
      }
    },
    removeModal(item) {
      document.getElementById("deletealert").innerText = item.name;
      this.selectedSliderId = item.id;
      this.content = item.detail;
      this.selecte
      this.selectedid = item.category_id;
      this.selectedCategoryName = this.categories.find(Slider => Slider.id == item.category_id).name
      this.selectedname = this.categories.find(Slider => Slider.id == item.category_id).name;
    },
    removeItem() {
      let selectedindex = this.items.findIndex(Slider => Slider.id == this.selectedSliderId);

      axios.delete(`https://api.falconflooringreno.ca/Slider/${this.selectedSliderId}`)
        .then(response => {
          console.log('Item deleted successfully:', response.data);
          this.items.splice(selectedindex, 1);
          document.getElementById('removemodalclose').click();
        })
        .catch(error => {
          if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
          console.error('Error deleting item:', error);
        });
    },


  },

  mounted() {



    document.body.className = this.className;
    document.title = this.pageTitle;
    //const route = useRoute();

    axios.get(`https://api.falconflooringreno.ca/Slider`)
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        console.log(error);
      });
    axios.get(`https://api.falconflooringreno.ca/categorylist`)
      .then(response => {
        this.categories = response.data;
      })
      .catch(error => {
        if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
        console.log(error);
      });
  }



}
</script>

<template>
    <!-- Site wrapper -->
    <div class="wrapper">
  
      <headerComp />
  
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1></h1>
              </div>
            </div>
          </div><!-- /.container-fluid -->
        </section>
  
        <!-- Main content -->
        <section class="content">
  
          <!-- Default box -->
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Business Partners</h3>
            
              <div class="card-tools">
                <input type="text" v-model="searchQuery" class="btn btn-tool" placeholder="Search for Partner..." 
  style="border: 1px solid gray; border-radius: 5px;"/>
                <button type="button" class="btn btn-tool" data-toggle="modal" data-target="#modal-xl-add">
                  New Partner <i class="fas fa-plus"></i>
                </button>
              </div>
  
  
            </div>
            <div class="card-body p-0">
              <table class="table table-striped projects">
                <thead>
                  <tr>
                    <th style="width: 1%">
                      #
                    </th>
                    <th style="width: 20%">
                      Partner Name
                    </th>
                    <th style="width: 8%" class="text-center">
                      Image
                    </th>
                    <th style="width: 20%">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!--<tr v-for="Partner in items" :key="Partner.id">-->
                    <tr v-for="Partner in paginatedItems" :key="Partner.id">
                    <td>{{ Partner.id }}</td>
                    <td><a>{{ Partner.name }}</a></td>
                    <td class="project-state">
                      <img :src="'https://api.falconflooringreno.ca/resize/'+ Partner.image" alt="Image from JSON"
                        style="width: 50px;height: 50px;object-fit: cover;" />
  
                    </td>
                    <td class="project-actions text-right">
                      <a class="btn btn-primary btn-sm" href="#" @click.prevent="openModal(Partner)" data-toggle="modal"
                        data-target="#modal-xl">
                        <i class="fas fa-folder"></i>
                        View
                      </a>
                      <a class="btn btn-info btn-sm" href="#" @click.prevent="editModal(Partner)" data-toggle="modal"
                        data-target="#modal-xl-edit">
                        <i class="fas fa-pencil-alt"></i>
                        Edit
                      </a>
                      <a class="btn btn-danger btn-sm" href="#" @click.prevent="removeModal(Partner)" data-toggle="modal"
                        data-target="#modal-danger">
                        <i class="fas fa-trash"></i>
                        Delete
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination">
    <button @click="prevPage" :disabled="currentPage <= 1">Previous</button>
    <span>Page {{ currentPage }}</span>
    <button @click="nextPage" :disabled="currentPage >= totalPages">Next</button>
  </div>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
  
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->
  
      <footerComp />
      <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
    <div class="modal fade" id="modal-danger">
      <div class="modal-dialog">
        <div class="modal-content bg-danger">
          <div class="modal-header">
            <h4 class="modal-title">Delete Partner</h4>
            <button type="button" class="close" data-dismiss="modal" id="removemodalclose" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure about delete Partner?&hellip;</p>
            <p id="deletealert"></p>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-outline-light" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-outline-light" @click.prevent="removeItem()">Delete</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  
    <div class="modal fade" id="modal-xl-add">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Partner Detail</h4>
            <button type="button" class="close" id="addmodalclose" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Partner Name</label>
                <input type="email" class="form-control" id="newPartnerNameInput" placeholder="Enter Partner Name">
              </div>
  
              <input type="hidden" id="Partnerid">
              <!--  <textarea id="summernote"  v-model="selectedPartnerDetail.desc"></textarea> /.card-header -->
              <div class="form-group">
                <label for="exampleInputFile">Image Input</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                      id="exampleInputFile">
                    <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to change</label>
                  </div>
                  <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="selam()">Upload</span>
                  </div>
                </div>
                <h5> {{ this.imagestatus }}</h5>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" @click.prevent="addPartner()" class="btn btn-primary">Add Partner</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="modal-xl-edit">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
          <h4 class="modal-title">Service Detail</h4>
          <button type="button" class="close" id="editmodalclose" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body">
            <div>
              <div class="form-group">
                <label for="exampleInputEmail1">Partner Name</label>
                <input type="email" class="form-control" id="PartnerName" placeholder="Enter Partner Name">
              </div>
  
              <input type="hidden" id="Partnerid">
              <!--  <textarea id="summernote"  v-model="selectedPartnerDetail.desc"></textarea> /.card-header -->
    
              <div class="form-group">
                <label for="exampleInputFile">File input</label>
                <div class="input-group">
                  <div class="custom-file">
                    <input type="file" @change="uploadImage" accept="image/*" class="custom-file-input"
                      id="exampleInputFile">
                    <label class="custom-file-label" for="exampleInputFile">Choose file, if you want to change</label>
                  </div>
                  <div class="input-group-append">
                    <span class="input-group-text" @click.prevent="selam()">Upload</span>
                  </div>
                </div>
                <h5> {{ this.imagestatus }}</h5>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal"
              @click="fetchData('myParameter')">Close</button>
            <button type="button" @click.prevent="updatePartner()" class="btn btn-primary">Save changes</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div class="modal fade" id="modal-xl">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Partner Detail</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="selectedPartner"
              style=" display: flex;flex-direction: column;align-items: center;justify-content: center;">
              <h3>Partner Name : {{ selectedPartner.name }}</h3>
              <hr>
  
            
              <img :src="'https://api.falconflooringreno.ca/resize/'+ selectedPartner.image" alt="Partner image" />
            </div>
            <div v-else>
              <p>No Partner selected</p>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal"
              @click="fetchData('myParameter')">Close</button>
  
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </template>
  
  <script>
  import headerComp from '../component/header.vue'
  import footerComp from '../component/footer.vue'
  //import modalBusiness Partners from '../component/modals.vue'
   import { state } from '../../store';
  import axios from 'axios';
 
axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
  export default {
    name: 'adminPartners',
    data() {
      return {
        items: [],
        searchQuery: '',
        currentPage: 1, // Current page number
         itemsPerPage: 10, // Number of items to display per page
        selectedCategoryName: null,
        selectedPartner: null,
        selectedPartnerId: null,
        selectedid: null,
        selectedname: null,
        content: null,
        content2: null,
        imagestatus: null,
        newimageUrl: null,
        newPartnerimageUrl: null
      };
    },
    components: {
      headerComp,
      footerComp,
      //  modalBusiness Partners
    },
    computed: {
      filteredItems() {
      if (this.searchQuery === '') {
        return this.items;
      }
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
      pageTitle() {
        return 'Partner List'; // replace with the desired title for this page
      },
      className() {
        return 'hold-transition sidebar-mini'; // replace with the desired title for this page
      }
    },
    methods: {
      prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
      async addPartner() {
        let newPartnerName = document.getElementById("newPartnerNameInput").value
  
        let myimgurl = null;
        if (this.newimageUrl !== null) {
          myimgurl = `${this.newimageUrl}`;
        }
        const newPartnerData = {
          "name": newPartnerName,
          "image": myimgurl,
        };
        try {
          const response = await axios.post(`https://api.falconflooringreno.ca/Partner`, newPartnerData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
  
          this.items.push(newPartnerData)
          document.getElementById('addmodalclose').click();
          console.log('Partner updated successfully:', response.data);
        } catch (error) {
          if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
          this.imagestatus = error;
          //console.error('Partner update failed:', error);
        }
      },
      async updatePartner() {
  
  
       
        let myimgurl = null;
        if (this.newimageUrl !== null) {
          myimgurl = `${this.newimageUrl}`;
        }
        const updatedData = {
          "id": document.getElementById("Partnerid").value,
          "name": document.getElementById("PartnerName").value,
          "image": myimgurl,
        };
        try {
          const response = await axios.patch(`https://api.falconflooringreno.ca/Partner`, updatedData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          let selectedindex = this.items.findIndex(Partner => Partner.id == parseInt(document.getElementById("Partnerid").value));
          this.items[selectedindex].name = document.getElementById("PartnerName").value;
          if (this.newimageUrl !== null) {
            this.items[selectedindex].image = myimgurl
          }
  
          this.selectedid = null;
          this.content = null;
          this.selectedid = null;
          this.selectedCategoryName = null;
  
  
          document.getElementById('editmodalclose').click();
          console.log('Partner updated successfully:', response.data);
        } catch (error) {
          if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
          this.imagestatus = error;
          //console.error('Partner update failed:', error);
        }
      },
      async uploadImage(event) {
        const formData = new FormData();
        const imageFile = event.target.files[0];
        formData.append('image', imageFile);
        try {
          const response = await axios.post('https://api.falconflooringreno.ca/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          this.newimageUrl = response.data.dosyaUrl
          this.imagestatus = `Image uploaded successfully: ${response.data.dosyaUrl}`;
        } catch (error) {
          if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
          this.newimageUrl = null
          this.imagestatus = `Image upload failed: ${error}`;
        }
      },
  
      openModal(item) {
        this.selectedPartner = item;
      },
      editModal(item) {
        console.log(item);
        document.getElementById("PartnerName").value = item.name;
        document.getElementById("Partnerid").value = item.id;
  
        this.selectedid = item.category_id;
      
        this.selectedPartnerDetail = {
          PartnerID: item.id,
          PartnerName: item.name,
          image: item.image
        }
      },
      removeModal(item) {
        document.getElementById("deletealert").innerText = item.name;
        this.selectedPartnerId = item.id;
      },
      removeItem() {
        let selectedindex = this.items.findIndex(Partner => Partner.id == this.selectedPartnerId);
  
        axios.delete(`https://api.falconflooringreno.ca/Partner/${this.selectedPartnerId}`)
          .then(response => {
            console.log('Item deleted successfully:', response.data);
            this.items.splice(selectedindex, 1);
            document.getElementById('removemodalclose').click();
          })
          .catch(error => {
            if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
            console.error('Error deleting item:', error);
          });
      },
  
  
    },
  
    mounted() {
  
  
  
      document.body.className = this.className;
      document.title = this.pageTitle;
      //const route = useRoute();
  
      axios.get(`https://api.falconflooringreno.ca/Partner`)
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          if (error.response.status === 401 && error.response.data.message === "error") {
          localStorage.removeItem('token');
          window.location.href = '/admin/login';
        }
          console.log(error);
        });
  
    }
  
  
  
  }
  </script>
  
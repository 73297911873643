
<template>
    <div class="col-md-4 col-xs-6" v-for="item in items" :key="item.name">
        <div class="single-service">
            <div class="image"><img v-bind:src="'https://api.falconflooringreno.ca/resize/'+item.image" alt=""></div>
            <div class="text style-one tran3s">
                <h4><a href="" class="tran3s">{{ item.name }}</a></h4>
                <p>{{ item.desc.slice(0, 95) }}</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'serviceList',
    data() {
        return {
            items: []
        };
    },
    mounted() {
        axios.get('https://api.falconflooringreno.ca/servicelist')
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
};
</script>
  

<template>
    <div class="shop-sidebar-list">
        <h3>Categories</h3>
        <ul>
            <li v-for="item in items" :key="item.id">
                <a :href="'/category/'+item.name" class="tran3s" >{{ item.name }} <i v-if="item.children" class="fa fa-angle-down" aria-hidden="true"></i></a>
                <ul class="sub-categories" v-if="item.children">
                    <li v-for="child in item.children" :key="child.id">
                        <a :href="'/category/'+child.name" class="tran3s">-{{ child.name }}<i v-if="child.children" class="fa fa-angle-down" aria-hidden="true"></i></a>
                        <ul class="sub-sub-categories" v-if="child.children">
                    <li v-for="childc in child.children" :key="childc.id">
                        <a :href="'/category/'+childc.name" class="tran3s">--{{ childc.name }}</a>
                    </li>
                </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
import axios from 'axios';
import { useRoute } from "vue-router";

export default {
    name: 'CategoryTree',
    data() {
        return {
            items: []
        };
    },
    mounted() {
        const route = useRoute();

        this.categoryId = "/" + route.params.categoryName ?? "";
        axios.get(`https://api.falconflooringreno.ca/categorypage`)
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
};
</script>
  
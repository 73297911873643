<template>
	<div class="main-page-wrapper">
		<HeaderComp />
		<div class="inner-banner">
			<div class="opacity">
				<div v-for="product in items" :key="product.id" class="container">
					<h2>{{product.name}}</h2>
					<p></p>
	
				</div> <!-- /.container -->
			</div> <!-- /.opacity -->
		</div>
		<Requestquote />
		<div class="request-quote-section">
		
			<div class="container">
				<div class="row">
					<div v-for="product in items" :key="product.id" v-html="product.desc"></div> <!-- /.text -->

					<div class="img-box float-left theme-col">
						<img src="frontend/images/home/6.jpg" alt="">
					</div> <!-- /.quote-form -->
				</div> <!-- /.row -->
				<p class="m1">
				</p>
			</div> <!-- /.container -->
		</div>
		




	<FooterComp />
</div></template>

<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import Requestquote from '../extas/requestquote.vue';
import axios from 'axios';
import { useRoute } from "vue-router";
export default {
	components: {
    HeaderComp,
    FooterComp,
    Requestquote
},
	name: 'pagePage',
    data() {
        return {
            items: [],
            pageName:null,
        };
    },
    mounted() {
        const route = useRoute();
	document.title = this.pageTitle;
        this.pageName = "/" + route.params.pageName ?? "";
        axios.get(`https://api.falconflooringreno.ca/page${this.pageName}`)
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    },
	computed: {
		pageTitle() {
			return 'Falcon Flooring & Renovation | About'; // replace with the desired title for this page
		}
	},
	



}
</script>

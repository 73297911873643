<template>
	<div id="loader-wrapper">
		<div id="loader"></div>
	</div>
	<header class="theme-main-header bg-color">
		<div class="container">
			<div class="theme-main-menu">
				<div class="clearfix">
					<div class="logo float-left"><a href="/Home"><img v-if="isSettingsLoaded" :src="settings[5].code" alt=""></a></div>
					<nav id="mega-menu-holder" class="float-right">
						<ul class="clearfix">
							<li :class="{ 'active': $route.name === 'home' }"><a href="/Home">Home</a>
							</li>
							<li :class="{ 'active': $route.name === 'services' }"><a href="/services">Services</a>

							</li>
							<li :class="{ 'active': $route.name === 'about' }"><a href="/about">About us</a>
							</li>
							<li :class="{ 'active': $route.name === 'categories' }"><a href="/category">PRODUCTS</a></li>
							<li :class="{ 'active': $route.name === 'page' }"><a href="">Pages</a>
								<ul class="dropdown"
									style="display: none; height: 103px; padding-top: 0px; margin-top: 0px; padding-bottom: 0px; margin-bottom: 0px;">
									<li v-for="(page, index) in pages" :key="index"><a :href="'/page/'+page.name">{{page.name}}</a></li>
								</ul>
								<span class="sub-toggle"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
							</li>
							<li class="last" :class="{ 'active': $route.name === 'ContactUsPage' }"><a
									href="/contactus">contact us</a></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import axios from 'axios';
import { watch } from 'vue';
import EventBus from '@/eventBus';
export default {
	name: 'HeaderComp',
	data() {
      return {
        pages: [],
		settings:null,
		isSettingsLoaded: false,
      };
    },
	


	mounted() {
  
		axios.get(`https://api.falconflooringreno.ca/setting`)
      .then(response => {
        this.settings = response.data;
        EventBus.emit('settings-loaded', this.settings);
		this.isSettingsLoaded = true;
      })
      .catch(error => {
        console.log(error);
      });
  
  //const route = useRoute();

  axios.get(`https://api.falconflooringreno.ca/page`)
    .then(response => {

        this.pages=response.data;
   
    })
    .catch(error => {
      console.log(error);
    });

	watch(() => this.settings,
    (newSettings) => {
	this.settings = newSettings;
	EventBus.emit('settings-updated', this.settings);
  });

}
  

}
</script>
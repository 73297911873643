<template>
	<div class="carousel" ref="carousel">
		<div class="carousel-cell" v-for="image in images" :key="image.id">
			<div class="slide-content" v-html="image.name+image.desc">
			</div>
			<img :src="image.image" style="height: 100%;width: 100%;" />

		</div>
	</div>
</template>
  
<script>
import Flickity from 'flickity';
import axios from 'axios';
import 'flickity/css/flickity.css';

export default {
	data() {
		return {
			images:[],
			flkty: null,
			options: {
				autoPlay: true, // enable auto play
				initialIndex: 1, // start with the second slide
				prevNextButtons: false, // hide prev/next buttons
				pageDots: true // hide page dots
			}
		};
	},
	mounted() {

	



		axios.get(`https://api.falconflooringreno.ca/slider`)
			.then(response => {
				this.images = response.data;
			})
			.catch(error => {
				console.log(error);
			});

		this.flkty = new Flickity(this.$refs.carousel, this.options);

	},
	methods: {


	},
};
</script>
  
<style scoped>
.carousel {
	width: 100%;
	margin: 0 auto;
}

.carousel-cell {
	width: 1903px;
	height: 950px;
	margin-right: 10px;
}

.carousel-cell .slide-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	color: #fff;
	line-height: 75px;
}

.carousel-cell .slide-content.is-visible {
	opacity: 1;
}
</style>
  
<template>


  <SliderComp />
<requestQuote />
			<div class="our-project">
				<div class="container">
					<div class="theme-title">
						<h2>Categories</h2>
					</div>
					<div id="mixitUp-item2" class="row" style="display: flex;flex-wrap: wrap;">
</div> 
				</div>
			</div>
			
</template>
<script>
import requestQuote from '../extas/requestquote.vue'
import SliderComp from './sliderComp.vue'
export default {
  name: 'HomeContent',
  data() {
		return {
			
			
		};
	},
  components:{
	requestQuote,
	SliderComp,
  },
  computed: {
    pageTitle() {
      return 'Falcon Flooring & Renovation'; // replace with the desired title for this page
	}
  },
mounted() {
    document.title = this.pageTitle;
  }
}

</script>
<template>
    <div class="login-box">
        <!-- /.login-logo -->
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <a href="" class="h1"><b>Falcon Flooring </b><br>Admin</a>
            </div>
            <div class="card-body">
                <p class="login-box-msg">Click Sign in to get login link</p>




                <div>
                    <div v-if="showMessage">
                        Please check your email box for the login link.
                    </div>
                    <div v-else>
                        <div class="row justify-content-center">
                            <button @click="generateHotlink" class="btn btn-primary btn-block">Sign In</button>
                        </div>
                    </div>
                </div>

                <!--
                <div class="social-auth-links text-center mt-2 mb-3">
                    <a href="#" class="btn btn-block btn-primary">
                        <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
                    </a>
                    <a href="#" class="btn btn-block btn-danger">
                        <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
                    </a>
                </div>
              

                <p class="mb-1">
                    <a href="forgot-password.html">I forgot my password</a>
                </p>
                <p class="mb-0">
                    <a href="register.html" class="text-center">Register a new membership</a>
                </p>
             -->
            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.login-box -->
</template>

<script>
import axios from 'axios';
import { setToken } from '../../store'; // Import the setToken function
export default {
    name: 'adminLogin',
    computed: {
        pageTitle() {
            return 'Admin Panel Login'; // replace with the desired title for this page
        },
        className() {
            return 'hold-transition login-page'; // replace with the desired title for this page
        }
    },
    data() {
        return {
            hotlink: null,
            showMessage: false,
        };
    },
    async mounted() {
        const token = this.$route.query.token;
        if (token) {
            try {
                const response = await axios.post('https://api.falconflooringreno.ca/verify-token', { token });
                if (response.data.success) {
                    //const globalState = inject('globalState'); // Inject the global state
                    //const token = computed(() => globalState.token);
                    setToken(token);

                    this.$router.push('/admin/products'); // Redirect the user to the admin panel
                } else {
                    console.log("sss")
                    //this.$router.push('/'); // Redirect the user to the home page
                }
            } catch (error) {
                console.log('Error verifying token:', error);
                // this.$router.push('/'); // Redirect the user to the home page
            }
        } else {
            console.log("sss")
            //this.$router.push('/'); // Redirect the user to the home page
        }
        document.body.className = this.className;
        document.title = this.pageTitle;
    },
    methods: {

        async generateHotlink() {
            try {
                const response = await axios.post("https://api.falconflooringreno.ca/generate-token");
                this.hotlink = response.data;
                this.showMessage = true;
            } catch (error) {
                console.error("Error generating hotlink:", error);
            }
        },
    },



}
</script>


<template>
    <div class="related-product all-product-wrapper">
        <h5>Releted Products</h5>
        <div class="row">
            <div v-if="items" class="related-product-slider">
                <Carousel :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in items" class="item" :key="item.id">
                        <div class="single-item">
                            <img :src="'https://api.falconflooringreno.ca/resize/'+item.image" alt="">
                            <h5><a :href="'/product/'+item.name" class="tran3s">{{ item.name }}</a></h5>
                            
                           
                        </div> <!-- /.single-item -->

                    </Slide>
                </Carousel>
            </div> <!-- /.related-product-slider -->
        </div> <!-- /.row -->
    </div> <!-- /.related-product -->
</template>
<script>

import axios from 'axios';
import { Carousel, Slide } from 'vue3-carousel';
export default {
    name: 'reletedProducts.vue',
    data() {
        return {
            items: [],
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            
            breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    },
        };


    },
    components: {
        Carousel,
        Slide,
    },
    mounted() {

        axios.get(`https://api.falconflooringreno.ca/popularproducts`)
            .then(response => {
                this.items = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
};
</script>
<style>
@import 'vue3-carousel/dist/carousel.css';
</style>
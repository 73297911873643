<template>
    <div class="partner-logos">
      <div class="logos-container">
        <div class="logo-container" v-for="(logo, index) in logos" :key="index">
          <img :src="'https://api.falconflooringreno.ca/resize/'+logo.image" :alt="logo.name" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
  export default {
    name: "PartnerLogos",
    data() {
      return {
        logos: [],
      };
    },
    mounted() {
  

  //const route = useRoute();

  axios.get(`https://api.falconflooringreno.ca/Partner`)
    .then(response => {

        this.logos=response.data;
   
    })
    .catch(error => {
      console.log(error);
    });

}
  };
  </script>
  
  <style scoped>
  .partner-logos {
    position: relative;
    overflow: hidden;
  }
  
  .logos-container {
    display: flex;
    white-space: nowrap;
    animation: scroll-left 40s linear infinite;
  }
  
  .logo-container {
    display: inline-block;
    padding: 10px;
  }
  
  .logo-container img {
    max-width: 100%;
    height: auto;
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  </style>
  
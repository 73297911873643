<template>
	<div class="main-page-wrapper">
		<HeaderComp />
		<div class="inner-banner">
			<div class="opacity">
				<div class="container">
					<h2>About us</h2>
					<p>Flooring &amp; Tiles has been a presence on the international market since 1962 and is part of
						<br>the Gruppo Concorde, the largest ceramic group in Europe.
					</p>

				</div> <!-- /.container -->
			</div> <!-- /.opacity -->
		</div>
		<Requestquote />
		<div class="request-quote-section">

			<div class="container">
				<div class="row">
					<div v-if="settings" v-html="settings[8].code" class="text float-left theme-col">
					</div> <!-- /.text -->

					<div class="img-box float-left theme-col">
						<img src="frontend/images/upload/6.jpg" alt="">
					</div> <!-- /.quote-form -->
				</div> <!-- /.row -->
				<p class="m1">
				</p>
			</div> <!-- /.container -->
		</div>





		<FooterComp />
	</div>
</template>

<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import Requestquote from '../extas/requestquote.vue';
import EventBus from '@/eventBus';
export default {
	components: {
		HeaderComp,
		FooterComp,
		Requestquote
	},
	data() {
		return {
			settings: null,
		};
	},
	created() {


		EventBus.on('settings-loaded', settings => {
			this.settings = settings;
		});
	},
	beforeUnmount() {
		EventBus.off('settings-loaded');
	},
	methods: {
		cleanHTML(html) {
			const div = document.createElement('div');
			div.innerHTML = html;
			return div.textContent || div.innerText || '';
		}
	},
	name: 'aboutPage',
	computed: {
		pageTitle() {
			return 'Falcon Flooring & Renovation | About'; // replace with the desired title for this page
		}
	},
	mounted() {
		document.title = this.pageTitle;
	}



}
</script>

<template>
    <GoogleMap api-key="AIzaSyAum7q4F94H-MiJP7ofmjneq0qXqTZoNjg" style="width: 100%; height: 500px" :center="center" :zoom="15">
      <Marker :options="{ position: center }" />
    </GoogleMap>
  </template>
<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
    name: 'GoogleMap2',
  components: { GoogleMap, Marker },
  setup() {
    const center = { lat: 43.76196259731175, lng: -79.53014040795209};
    const markerOptions = { position: center, label: "F", title: "Falcon Floring & Renovation" };

    return { center, markerOptions };
  },
});
</script>
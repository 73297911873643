<div class="row">
    <productList />
</div> <!-- /.row -->
<ul class="shop-page-pagination clearfix">
    <li class="float-left active"><a href="" class="tran3s">1</a></li>
    <li class="float-left"><a href="" class="tran3s">2</a></li>
    <li class="float-left"><a href="" class="tran3s">3</a></li>
    <li class="float-left"><a href="" class="tran3s">Next</a></li>
</ul>


<template>
   <div class="input-container">
    <label for="search-input" class="input-label">Search:</label>
     <input data-v-43b93cf2="" type="text" class="search-input" v-model="searchQuery" placeholder="Product Search..." style="border: 1px solid gray; border-radius: 5px;" />
            </div>
    <div class="row" style="display: flex;flex-wrap: wrap;">
      <div class="col-sm-4 col-xs-6" v-for="item in paginatedItems" :key="item.name">
        <div class="single-item">
          <img v-bind:src="'https://api.falconflooringreno.ca/resize/'+item.image" alt="">
          <h5><a :href="'/product/'+item.name" class="tran3s">{{ item.name.length > 65 ? item.name.substring(0, 65) + '...' : item.name }}</a></h5>
        </div>
      </div>
    </div>
    <ul class="shop-page-pagination clearfix">
    <li class="float-left" :class="{ active: currentPage !== 1 }"><a class="tran3s" :class="{ activetag: currentPage !== 1 }" @click="goToPreviousPage">Previous</a></li>
    <li class="float-left">{{ currentPage }} of {{ totalPages }}</li>
    <li class="float-left" :class="{ active: currentPage !== totalPages }"><a class="tran3s" :class="{ activetag: currentPage !== totalPages }" @click="goToNextPage">Next</a></li>
    </ul>
      
 
  </template>
  <style scoped>
  .activetag {
    background: #464646;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff !important;
  }
  .search-input {
    
    margin-bottom: 16px; /* Adjust the value as needed */
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  outline: none;
  transition: all 0.3s;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.search-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}
.input-container {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Add this line */
}
.input-label {
  font-size: 16px; /* Adjust the value as needed */
}

  </style>
  
<script>
import axios from 'axios';
import { useRoute } from "vue-router";

export default {
  name: 'productList',
  data() {
    return {
      searchQuery: '',
      categoryId: null,
      items: [],
      currentPage: 1,
      itemsPerPage: 12
    };
  },
  computed: {
    filteredItems() {
      if (this.searchQuery === '') {
        return this.items;
      }
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    }
  },
  methods: {
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    }
  },
  mounted() {
    const route = useRoute();

    this.categoryId = "/" + route.params.categoryName ?? "";
    axios.get(`https://api.falconflooringreno.ca/products${this.categoryId}`)
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};

</script>
  
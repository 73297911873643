<template>
   <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | mrb` : `mrb` }}</template>
  </metainfo>
<div class="main-page-wrapper">
  <HeaderComp />
<HomeContent />
			<FooterComp />
		</div>
</template>

<script>
import HeaderComp from '../header.vue';
import FooterComp from '../footer.vue';
import HomeContent from './homecontent.vue'
export default {
  components: {
  HomeContent,
  HeaderComp,
  FooterComp,
  },
  name: 'HelloWorld',


  
}
</script>

<template>
    <PartnerLogos />
    <div class="bottom-banner">
        <div class="container">
            <ul class="row" v-if="settings">
                <li>
                    <div>
                        <a :href="'tel:' + cleanHTML(settings[0].code)">
                            <i class="flaticon-technology"></i>
                            <h6>Contact info</h6>
                            <p v-html="settings[0].code + cleanHTML(settings[3].code)"></p>
                        </a>
                    </div>
                </li>
                <li>
                    <div>
                        <a href="#" class="tran3s">
                            <h6>Request for a Quote</h6>
                            <p>Feel free talk to us</p>
                        </a>
                    </div>
                </li>
                <li>
                    <div><a href="https://goo.gl/maps/DB239ooQjQhQ8qHQ9">
                            <i class="flaticon-location-1"></i>
                            <h6>Location</h6>
                            <p v-html="settings[1].code"></p>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <footer>

        <div class="container">
            <div class="top-footer row">
                <div class="col-md-5 col-sm-8 col-xs-12 footer-logo">
                    <a href="#"><img v-if="settings" :src="settings[5].code" alt="Logo"></a>
                    <p v-if="settings" v-html="settings[7].code"></p>
                    <ul>
                        <li>
                            <a v-if="settings && settings[9] && settings[9].code"
                                :href="'https://facebook.com/' + settings[9].code" class="tran3s">
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li><a v-if="settings && settings[10] && settings[10].code"
                                :href="'https://instagram.com/' + settings[10].code" class="tran3s"><i class="fa fa-instagram"
                                    aria-hidden="true"></i></a></li>
                        <li><a v-if="settings && settings[11] && settings[11].code"
                                :href="'https://linkedin.com/' + settings[11].code" class="tran3s"><i class="fa fa-linkedin"
                                    aria-hidden="true"></i></a></li>
                        <li><a v-if="settings && settings[12] && settings[12].code"
                                :href="'https://dribbble.com/' + settings[12].code" class="tran3s"><i class="fa fa-dribbble"
                                    aria-hidden="true"></i></a></li>
                        <li><a v-if="settings && settings[13] && settings[13].code"
                                :href="'https://twitter.com/' + settings[13].code" class="tran3s"><i class="fa fa-twitter"
                                    aria-hidden="true"></i></a></li>
                        <li><a v-if="settings && settings[14] && settings[14].code"
                                :href="'https://vimeo.com/' + settings[14].code" class="tran3s"><i class="fa fa-vimeo"
                                    aria-hidden="true"></i></a></li>
                        <li><a v-if="settings && settings[15] && settings[15].code"
                                :href="'https://youtube.com/' + settings[15].code" class="tran3s"><i class="fa fa-youtube"
                                    aria-hidden="true"></i></a></li>

                    </ul>
                </div>
                <div id="footercats" class="col-md-3 col-sm-4 col-xs-12 footer-list">
                </div>
                <div class="col-md-4 col-xs-12 newsletter">
                    <h6>Newsletter</h6>
                    <form action="#">
                        <input type="text" placeholder="Name">
                        <input type="email" placeholder="Email">
                        <button class="tran3 p-bg-color theme-button hvr-float-shadow">SUBSCRIBE Now</button>
                    </form>
                </div>
            </div>
        </div>
        <div class="bottom-footer">
            <div class="container">
                <p class="float-left">Copyright &copy; 2023 <a href="#" class="tran3s p-color">Falcon Flooring &
                        Renovation</a> Powered by <a href="https://mediamind.ca/website" class="tran3s p-color"
                        target="_blank">Media Mind</a></p>
                <ul class="float-right">
                    <li><a href="/about" class="tran3s">About</a></li>
                    <li>|</li>
                    <li><a href="/contactus" class="tran3s">Contact</a></li>
                </ul>
            </div>
        </div>
    </footer>
    <button class="scroll-top tran3s p-bg-color">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </button>
</template>

<script>
import PartnerLogos from './extas/PartnerLogos.vue'
//import axios from 'axios';
import EventBus from '@/eventBus';
export default {
    data() {
        return {
            settings: null,
        };
    },
    created() {


        EventBus.on('settings-loaded', settings => {
            this.settings = settings;
        });
    },
    beforeUnmount() {
        EventBus.off('settings-loaded');
    },
    name: 'FooterComp',
    components: {
        PartnerLogos,
    },
    methods: {
        cleanHTML(html) {
            const div = document.createElement('div');
            div.innerHTML = html;
            return div.textContent || div.innerText || '';
        }
    },


}
</script>
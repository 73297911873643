import { reactive } from 'vue';

export const state = reactive({
    token: localStorage.getItem('token') || null
});

export function setToken(token) {
  state.token = token;
  localStorage.setItem('token', token);
}

export function removeToken() {
    state.token = null;
    localStorage.removeItem('token');
  }